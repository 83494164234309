import { useDispatch } from 'react-redux'
import React from 'react'
import CommonCheckbox from 'common/components/entities/Checkbox'
import CommonText from 'common/components/entities/TextNew/ui/TextUi'
import { CheckboxInterface } from 'common/types/entities/CheckboxInterface'
import { toggleExplicitConsent } from 'publisher/actionsManagement'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { managementSelectors, useManagement, usePage } from 'publisher/store'
import pageSelectors from 'publisher/store/page/pageSelectors'

interface CheckboxProps {
  entity: CheckboxInterface
}

const Checkbox = ({ entity }: CheckboxProps) => {
  const dispatch = useDispatch()
  const globalLinkColor = usePage(pageSelectors.getGlobalLinkColor)
  const globalTextColor = usePage(pageSelectors.getGlobalTextColor)
  const globalFontSize = usePage(pageSelectors.getGlobalTextFontSize)
  const mobileGlobalFontSize = usePage(
    pageSelectors.getGlobalMobileTextFontSize,
  )
  const globalTextLineHeight = usePage(pageSelectors.getGlobalTextLineHeight)
  const globalMobileTextLineHeight = usePage(
    pageSelectors.getGlobalMobileTextLineHeight,
  )
  const fontProperties = usePage(pageSelectors.getGlobalFontProperties)

  const fontFamily = entity.fontFamily || fontProperties.fontFamily

  const mobileFontFamily = entity.mobileFontFamily || fontFamily

  const toggleConfirmed = () => dispatch(toggleExplicitConsent(entity.id))
  const isConfirmed = useManagement(m =>
    managementSelectors.getConfirmedCheckboxesIds(m).includes(entity.id),
  )

  if (typeof window === 'undefined') {
    return (
      <StyleWrapper
        mobileMargin={entity.mobileMargin}
        margin={entity.margin}
        appearance={entity.appearance}
      />
    )
  }

  return (
    <StyleWrapper
      mobileMargin={entity.mobileMargin}
      margin={entity.margin}
      appearance={entity.appearance}
    >
      <CommonCheckbox
        entity={entity}
        checked={isConfirmed}
        onChangeHandler={toggleConfirmed}
      >
        <CommonText
          id={entity.htmlAttrId}
          linkColor={globalLinkColor}
          fontSize={entity.fontSize || globalFontSize}
          mobileFontSize={entity.mobileFontSize || mobileGlobalFontSize}
          lineHeight={entity.lineHeight || globalTextLineHeight}
          mobileLineHeight={
            entity.mobileLineHeight || globalMobileTextLineHeight
          }
          color={entity.color || globalTextColor}
          fontFamily={fontFamily}
          mobileFontFamily={mobileFontFamily}
          fontStyle={entity.fontStyle}
          mobileFontStyle={entity.mobileFontStyle}
          fontWeight={entity.fontWeight || fontProperties.fontWeight}
          mobileFontWeight={entity.mobileFontWeight}
          padding={entity.padding}
          mobilePadding={entity.mobilePadding}
          textAlign={entity.textAlign}
          mobileTextAlign={entity.mobileTextAlign}
          dangerouslySetInnerHTML={{ __html: entity.html || '' }}
        ></CommonText>
      </CommonCheckbox>
    </StyleWrapper>
  )
}

export default Checkbox
